export class Calendar {
  year: number
  month: number
  day: number
  date: Date
  state: string
  disabled: boolean
  active: boolean

  constructor(
    year: number,
    month: number,
    day: number,
    date: Date,
    state: string,
    disabled: boolean,
    active: boolean
  ) {
    this.year = year
    this.month = month
    this.day = day
    this.date = date
    this.state = state
    this.disabled = disabled
    this.active = active
  }
}

export class MonthlyCalendar {
  year: number
  month: number
  date: Date
  state: string
  disabled: boolean
  active: boolean

  constructor(
    year: number,
    month: number,
    date: Date,
    state: string,
    disabled: boolean,
    active: boolean
  ) {
    this.year = year
    this.month = month
    this.date = date
    this.state = state
    this.disabled = disabled
    this.active = active
  }
}
