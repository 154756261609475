
import { computed, reactive, watch } from "vue"
import { addDay, equalsDay } from "@/assets/script/utils/date.util"
import { Calendar } from "./script/model"
import {
  MonthSleepScoreVo,
  SleepAceService
} from "@/assets/script/service/sleep-ace.service"
import { format } from "@/assets/script/utils/sleep-ace.util"
import { useI18n } from "vue-i18n"
import cookies from "js-cookie"
import { i18n } from "../../../../locales"

interface Props {
  modelValue: Date
  equipmentId: string
  leftRight: 0 | 1
}

interface State {
  date: Date
  year: number
  month: number
  firstDayDate: Date
  lastDayDate: Date
  calendarDataList: Calendar[]
  monthSleepScoreList: MonthSleepScoreVo[]
}

export default {
  props: ["modelValue", "equipmentId", "leftRight"],
  setup(props: Props) {
    const { t } = useI18n()
    const weekDescList = i18n.global.tm("calendar.weekDescList")

    const state: State = reactive({
      monthSleepScoreList: [],
      date: computed(() => props.modelValue),
      year: props.modelValue.getFullYear(),
      month: props.modelValue.getMonth(),
      firstDayDate: computed(() => new Date(state.year, state.month, 1)),
      lastDayDate: computed(() => new Date(state.year, state.month + 1, 0)),
      calendarDataList: computed(() => {
        const calendarDataList = []
        const { lastDayDate, firstDayDate } = state
        for (let i = 0; i < lastDayDate.getDate(); i++) {
          const date = addDay(firstDayDate, +i)
          const disabled = false
          const active = equalsDay(date, props.modelValue)
          calendarDataList.push(
            new Calendar(
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate(),
              date,
              format(
                state.monthSleepScoreList.find(
                  (it) => new Date(it.time).getDate() === date.getDate()
                )?.value ?? 0
              ).style,
              disabled,
              active
            )
          )
        }
        return calendarDataList
      })
    })
    const change = (step: number) => {
      const target = state.month + step
      if (target === 12) {
        state.year += 1
        state.month = 0
      } else if (target === -1) {
        state.year -= 1
        state.month = 11
      } else {
        state.month = target
      }
    }
    watch(
      () => state.date,
      (val) => {
        state.year = val.getFullYear()
        state.month = val.getMonth()
      }
    )
    watch(
      () => ({
        year: state.year,
        month: state.month,
        equipmentId: props.equipmentId,
        leftRight: props.leftRight
      }),
      async () => {
        state.monthSleepScoreList =
          (
            await SleepAceService.listMonthSleepScore({
              equipmentId: props.equipmentId,
              leftRight: props.leftRight,
              month: state.month + 1,
              year: state.year
            })
          ).data ?? []
      },
      {
        immediate: true
      }
    )

    return {
      weekDescList,
      change,
      state
    }
  }
}
