
import { ref, watch } from "vue"

import Calendar from "./components/Calendar.vue"
import Position from "./components/Position.vue"
import DateType from "./components/DateType.vue"
import ScoreAnalyse from "./components/ScoreAnalyse.vue"
import HeartRate from "./components/HeartRate.vue"
import BreathRate from "./components/BreathRate.vue"
import SleepCurve from "./components/SleepCurve.vue"
import SnoreLevel from "./components/SnoreLevel.vue"
import TurnOver from "./components/TurnOver.vue"
import Temperature from "./components/Temperature.vue"
import Humidity from "./components/Humidity.vue"
import Deduct from "./components/Deduct.vue"
import { addDay } from "@/assets/script/utils/date.util"
import { duration } from "@/assets/script/utils/sleep-ace.util"
import {
  SleepDataVo,
  SleepAceService
} from "@/assets/script/service/sleep-ace.service"
import { Toast } from "vant"
import { Ref, UnwrapRef } from "@vue/reactivity"
import { useI18n } from "vue-i18n"
import cookies from "js-cookie"
import { useRouter } from "vue-router"

interface Props {
  equipmentId: string
  date?: string
  position?: string
}

export default {
  props: ["equipmentId", "date", "position"],
  components: {
    Calendar,
    Position,
    DateType,
    ScoreAnalyse,
    HeartRate,
    BreathRate,
    TurnOver,
    SleepCurve,
    SnoreLevel,
    Temperature,
    Humidity,
    Deduct
    // DateType
  },
  setup(props: Props) {
    const router = useRouter()

    // // 从路由参数中获取 locale 参数值，如果没有则默认为 "cn"
    // const localeArray = router.currentRoute.value.query.locale || "cn";

    // // 将 localeArray 转换为字符串，如果是数组则取第一个元素，否则使用默认值 "cn"
    // const locale = Array.isArray(localeArray) ? localeArray[0] || "cn" : localeArray || "cn";

    // // 从 Cookie 中获取当前保存的语言设置
    // const currentLocale = cookies.get("locale");

    // // 检查新的语言设置是否与当前设置不同
    // if (locale !== currentLocale) {
    //   // 设置新的语言首选项到 Cookie 中
    //   cookies.set("locale", locale, { expires: 365 * 100 }); // 100年
    //   // 重新加载页面一次
    //   window.location.reload();
    // } else {
    //   // 否则，不需要重新加载页面
    //   console.log("语言设置未更改，不需要重新加载页面");
    // }

    const { t } = useI18n()
    // console.log(props)
    const dialogShow = ref(false)
    const currDate = ref(
      props.date && !(props.date === "undefined")
        ? new Date(props.date)
        : addDay(new Date(), -1)
    )
    const leftRight: Ref<UnwrapRef<0 | 1>> = ref(
      (Number(props.position || 0) || 0) as 0 | 1
    )
    const emptyMessage = ref("")
    const changeDate = (step: number) => {
      currDate.value = addDay(currDate.value, step)
    }
    const sleepDataList: Ref<UnwrapRef<SleepDataVo[] | undefined>> = ref(
      undefined
    )

    const activeList: Ref<UnwrapRef<number[]>> = ref([0])

    const listSleepData = async () => {
      const toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner"
      })

      const result = await SleepAceService.listSleepData({
        day: currDate.value.getDate(),
        equipmentId: props.equipmentId,
        leftRight: leftRight.value,
        month: currDate.value.getMonth() + 1,
        year: currDate.value.getFullYear()
      })
      emptyMessage.value = result.message ?? ""
      toast.clear()
      activeList.value = [0]
      // sleepDataList.value = result.data
      sleepDataList.value = result.data
      // console.log(sleepDataList.value)
    }

    const changeCard = (index: number) => {
      const sourceIndex = activeList.value.indexOf(index)
      if (sourceIndex !== -1) {
        activeList.value.splice(sourceIndex, 1)
      } else {
        activeList.value.push(index)
      }
    }
    listSleepData()
    watch(
      () => {
        return {
          date: currDate.value,

          leftRight: leftRight.value
        }
      },
      () => {
        listSleepData()
      }
    )
    return {
      activeList,
      dialogShow,
      emptyMessage,
      currDate,
      leftRight,
      changeDate,
      sleepDataList,
      changeCard,
      duration
    }
  }
}
