
import { ref, watch } from "vue"
import { SetupContext } from "@vue/runtime-core"
import { useI18n } from "vue-i18n"

export default {
  props: ["modelValue"],
  setup(props: Record<"modelValue", number>, ctx: SetupContext) {
    const groupName = "radio"
    const value = ref(props.modelValue)
    const { t } = useI18n()
    watch(
      () => value.value,
      (val) => {
        ctx.emit("update:modelValue", val)
      }
    )

    return {
      groupName,
      value
    }
  }
}
